import { Component } from '@angular/core';
import { CONFIG } from '../config';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public config: any;
  constructor() {
    this.config = CONFIG;
  }
}
