export const CONFIG = {
    title: 'Get More Market',
    name: 'Angelic Loreto',
    email: 'angelica@grupoorus.mx',
    phones: [
        '+52 81 2747 0209',
    ],
    address: 'Calle Los Fresnos 175. Col. Santa Engracia CP. 66267 San Pedro Garza García.',
    addressMap: 'https://goo.gl/maps/gyWRTSDKvhm7Mi7w7',
    social: {
        // facebook: 'https://www.facebook.com/djsolis99/',
        // instagram: 'https://www.instagram.com/_djesussolis_/',
        // twitter: '',
        facebook: null,
        instagram: 'https://www.instagram.com/angelicloreto/',
        twitter: null,
        phone: '+528127470209',
        linkedin: 'https://www.linkedin.com/in/angelicloretto/',
        whatsapp: 'https://wa.me/528127470209?text=Hola Angelica, te contacto desde tu tarjeta de presentacion. ',
        webPage: null
    },
    logo: '/assets/GMM.jpeg',
    bottomLogo: '/assets/imdi.jpeg',
    colors: {
        background: '#000000',
        foreground: '#FFFFFF',
        foregroundTitle: '#e66432'
    }
}